<!--
 * @Description: 客户属性管理
 * @Author: ChenXueLin
 * @Date: 2021-10-08 09:43:16
 * @LastEditTime: 2022-07-15 16:43:08
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="attrName">
              <el-input
                v-model="searchForm.attrName"
                placeholder="属性名称"
                title="属性名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i
            class="e6-icon-export_fill"
            @click="exportCorpAttr"
            title="导出"
          ></i>
          <!-- <i class="e6-icon-clear_line" @click="deleteHandle" title="删除"></i> -->
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="filterData"
          highlight-current-row
          @selection-change="handleSelectionChange"
        >
          <!-- <el-table-column type="selection" width="40"></el-table-column> -->
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          ></el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="toDetail($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <!-- <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section> -->
      <!-- 分页 end -->
    </div>
  </div>
</template>
<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { batchDeleteCorpAttr, exportCorpAttr, findDownList } from "@/api";
import { exportData } from "@/utils/download";
import { printError } from "@/utils/util";
export default {
  name: "CustomerAttrList",
  data() {
    return {
      activeName: "second",
      searchForm: {
        attrName: "", //属性名称
        pageIndex: 1,
        pageSize: 20
      },
      total: 0,
      columnData: [
        {
          fieldName: "codeName",
          display: true,
          fieldLabel: "属性名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "remark",
          display: true,
          fieldLabel: "备注",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      selColumnId: [], // 选中的数据
      tableData: [], // 表格数据
      filterData: [] // 过滤后的表格数据
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  created() {
    this.initData();
  },
  mounted() {},

  methods: {
    // 初始化可操作的枚举
    async initData() {
      try {
        this.loading = true;
        //获取枚举数据
        let promiseList = [findDownList(["corpAttrName"])];
        let [findDownListRes] = await Promise.all(promiseList);
        this.tableData = this.getFreezeResponse(findDownListRes, {
          path: "data.corpAttrName"
        });
        this.filterData = this.tableData;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取列表
    queryList() {
      this.filterval = this.searchForm.attrName;
      this.filterData = this.tableData.filter(item => {
        if (!this.filterval) {
          return true;
        }
        return item.codeName.indexOf(this.filterval) !== -1;
      });
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    //删除询问
    deleteHandle() {
      if (!this.selColumnId.length) {
        this.$message.warning("请选择要操作的数据");
        return;
      } else {
        this.$confirm("是否确认删除所选属性?", "删除属性", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            this.deleteAttr();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除"
            });
          });
      }
    },
    // 删除属性
    async deleteAttr() {
      try {
        this.loading = true;
        let res = await batchDeleteCorpAttr(this.selColumnId);
        if (res.code == "OK") {
          this.$message.success("删除成功");
          this.selColumnId = [];
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 导出
    exportCorpAttr() {
      exportData(this, exportCorpAttr);
    },
    // 表格多选
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => {
        return item.id;
      });
    },
    toDetail(val, row) {
      this.routerPush({
        name: "customerManage/customerAttrAddAttr",
        params: {
          refresh: true,
          id: `${row.id}`,
          attrName: row.codeName,
          originRouteName: "customerManage/customerAttr"
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped></style>
